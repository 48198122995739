import React from 'react';
import { NavLink } from 'react-router-dom';

const NavBar = () => (
  <nav>
    <ul>
      <li>
        <NavLink activeClassName="is-active" exact to="/">
          Home
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="is-active" to="/about-us">
          About Us
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="is-active" to="/products">
          Products
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="is-active" to="/services">
          Services
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="is-active" to="/feedback">
          Feedback
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="is-active" to="/contact-us">
          Contact Us
        </NavLink>
      </li>
    </ul>
  </nav>
);

export default NavBar;
