import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavBar from './NavBar';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Products from './pages/Products';
import Services from './pages/Services';
import Articles from './pages/Articles';
import Feedback from './pages/Feedback';
import NotFound from './pages/NotFound';
import ContactUs from './pages/ContactUs';
import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <NavBar />
          <div id="page-body">
            <Switch>
              <Route path="/" component={Home} exact />
              <Route path="/about-us" component={AboutUs} />
              <Route path="/products" component={Products} />
              <Route path="/services" component={Services} />
              <Route path="/articles" component={Articles} />
              <Route path="/feedback" component={Feedback} />
              <Route path="/contact-us" component={ContactUs} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}
export default App;
